// Inside Services.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Audience_Connection from "../Pages/Audience_Connection";
import Innovation_in_Media from "../Pages/Innovation_in_Media";
import Measurable_Impact from "../Pages/Measurable_Impact";
import Proven_Track_Record from "../Pages/Proven_Track_Record";
import Strategic_Storytelling from "../Pages/Strategic_Storytelling";
import Synergy from "../Pages/Synergy";

const Services = () => {
  return (
    <Routes>
      <Route path="/service1" element={<Audience_Connection />} />
      <Route path="/service2" element={<Innovation_in_Media />} />
      <Route path="/service3" element={<Measurable_Impact />} />
      <Route path="/service4" element={<Proven_Track_Record />} />
      <Route path="/service5" element={<Strategic_Storytelling />} />
      <Route path="/service6" element={<Synergy />} />
    </Routes>
  );
};

export default Services;
