// Home.js
import React from "react";
import banner from "../../assets/banner.mp4";
import {
  FaBullhorn, // Icon for Strategic Storytelling
  FaUserFriends, // Icon for Audience Connection
  FaTrophy, // Icon for Proven Track Record
  FaLightbulb, // Icon for Innovation in Media
  FaHandshake, // Icon for Collaborative Partnership
  FaChartLine, // Icon for Measurable Impact
} from "react-icons/fa";
import { FaSmile, FaTasks, FaRocket, FaClock } from "react-icons/fa";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./Home.css";
import Testimonials from "../Testimonials/Testimonials";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="main">
      <div className="custom-video-container">
        <video autoPlay loop muted playsInline className="custom-video">
          <source src={banner} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay">
          <h1>
            Creativity with Purpose, <br />
            Stories Beyond Borders.
          </h1>
          <p>
            Crafting Narratives, Fostering Change, Celebrating Diversity.
            <br /> Join Us on a Journey of Impactful Storytelling and Positive
            Transformation.
          </p>
        </div>
      </div>

      <Container fluid className="text-center my-5 px-5 ">
        <Row className="justify-content-center">
          {/* Paragraph spanning two cards */}
          <Col xs={12} md={6} lg={6}>
            <button className="top-rated-button mt-5" disabled>
              Top Rated
            </button>
            <h3>Our Services</h3>
            <h6>
              Tailored development services that create memorable experiences.
            </h6>
          </Col>

          {/* Card 1 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <div className="card">
              {" "}
              <Link to="/service5" style={{ color: "black" }}>
                <h3 className="mb-4">
                  <FaBullhorn className="icon" /> Strategic Storytelling:
                </h3>
                <p className="mb-3">
                  Crafting captivating narratives that go beyond advertising to
                  reveal the essence of your brand.
                </p>
              </Link>
            </div>
          </Col>

          {/* Card 2 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <div className="card">
              {" "}
              <Link to="/service1" style={{ color: "black" }}>
                <h3 className="mb-4">
                  <FaUserFriends className="icon" /> Audience Connection:
                </h3>
                <p className="mb-3">
                  Forge meaningful relationships through content tailored to
                  your audience, backed by meticulous market research.
                </p>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          {/* Card 3 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <div className="card">
              {" "}
              <Link to="/service3" style={{ color: "black" }}>
                <h3 className="mb-4">
                  <FaChartLine className="icon" /> Measurable Impact:
                </h3>
                <p className="mb-3">
                  Achieve tangible results as we focus on measurable impacts,
                  ensuring every campaign contributes to your success.
                </p>
              </Link>
            </div>
          </Col>

          {/* Card 4 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <div className="card">
              <Link to="/service4" style={{ color: "black" }}>
                <h3 className="mb-4">
                  <FaTrophy className="icon" /> Proven Track Record:
                </h3>
                <p className="mb-3">
                  Elevate your brand with our successful track record, catering
                  to businesses of all sizes with impactful campaigns.
                </p>
              </Link>
            </div>
          </Col>

          {/* Card 5 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <div className="card">
              {" "}
              <Link to="/service2" style={{ color: "black" }}>
                <h3 className="mb-4">
                  <FaLightbulb className="icon" /> Innovation in Media:
                </h3>
                <p className="mb-3">
                  Stay ahead in the dynamic media landscape with our innovative
                  approach across digital trends.
                </p>
              </Link>
            </div>
          </Col>

          {/* Card 6 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <div className="card">
              <Link to="/service6" style={{ color: "black" }}>
                <h3 className="mb-4">
                  <FaHandshake className="icon" /> Synergy:
                </h3>
                <p className="mb-3">
                  Experience a collaborative journey, where your goals, values,
                  and vision are at the core of our dedicated partnership.
                </p>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className="text-center my-5"
        style={{ backgroundColor: "rgb(9,67,76)", paddingTop: "20px" }}
      >
        <Row className="justify-content-center">
          {/* Card 1 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <Card className="card">
              <FaSmile className="icon1" />
              <h1>100+</h1>
              <h3 className="mb-4">Satisfied Clients</h3>
            </Card>
          </Col>

          {/* Card 2 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <Card className="card">
              <FaTasks className="icon1" />
              <h1>20+</h1>
              <h3 className="mb-4">Project Completed</h3>
            </Card>
          </Col>

          {/* Card 3 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <Card className="card">
              <FaRocket className="icon1" />
              <h1>15+</h1>
              <h3 className="mb-4">Project Launched</h3>
            </Card>
          </Col>

          {/* Card 4 */}
          <Col xs={12} md={6} lg={3} className="mb-4">
            <Card className="card">
              <FaClock className="icon1" />
              <h1>10+</h1>
              <h3 className="mb-4">Years in Business</h3>
            </Card>
          </Col>
        </Row>
      </Container>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Testimonials</h1>
        <h2>Our Partners Speak: Hear It Directly from Them!</h2>
        {/* Testimonials */}
        <Testimonials />
      </div>
    </div>
  );
};

export default Home;
