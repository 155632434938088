import React from "react";
import banner1 from "../../assets/banner1.png";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="main">
      <div className="custom-image-container">
        <img src={banner1} alt="Banner" className="custom-image" />
        <div className="image-overlay">
          <h1>Contact</h1>
        </div>
      </div>
      <Container className="my-5">
        <Row className="mx-2 mx-md-5">
          <Col xs={12} md={8} lg={12} className="mx-auto">
            <div className="contact-content">
              <div className="thank-you">
                <p>
                  We value your interest in connecting with Sambriddhi Media
                  Advertising Agency, and we're here to provide assistance in
                  any way possible.
                </p>
                <p>
                  Whether you have inquiries, suggestions, or simply want to
                  extend a warm greeting, we welcome the opportunity to engage
                  with you! Your input is important to us, and we look forward
                  to hearing from you soon.
                </p>
              </div>
              <div className="contact-details">
                <div className="email">
                  <a href="mailto:your-email@example.com">
                    {" "}
                    <FaEnvelope className="icon2" size={50} />
                  </a>
                  <div className="info">
                    <a href="mailto:your-email@example.com">Email</a>
                  </div>
                </div>
                <div className="phone">
                  <a href="tel:+1234567890">
                    {" "}
                    <FaPhoneAlt className="icon2" size={50} />
                  </a>
                  <div className="info">
                    <a href="tel:+1234567890"> Phone</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="Map"
                className="embed-responsive-item"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1809516.319732952!2d83.9259026175372!3d27.648925458816727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eae2aff36f02ff%3A0x449ae95e88092811!2sBagmati%20Province!5e0!3m2!1sen!2snp!4v1706266435828!5m2!1sen!2snp"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
