import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./Navbar.css"; // Import the custom CSS file

const CustomNavbar = () => {
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      variant="light"
      className={`custom-navbar sticky-top ${scrolling ? "scrolled" : ""}`}
      style={{
        backgroundColor: scrolling ? "white" : "transparent",
        color: scrolling ? "black" : "white",
        boxShadow: scrolling ? "0px 4px 10px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <Navbar.Brand href="/">
        <a href="/" className="brand-link">
          <h2 className="mx-5 my-3">SMAA</h2>
        </a>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav">
        <Nav className="ml-auto ">
          <Nav.Link href="/">
            <h5>Home</h5>
          </Nav.Link>
          <Nav.Link href="/about">
            <h5>About</h5>
          </Nav.Link>

          <NavDropdown
            title="Services"
            id="basic-nav-dropdown"
            style={{
              fontSize: "20px",
              fontWeight: "600",
              paddingBottom: "10px",
            }}
          >
            <NavDropdown.Item
              href="/service1"
              style={{ color: "black", paddingLeft: "5px" }}
            >
              Audience Connection
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/service2"
              style={{ color: "black", paddingLeft: "5px" }}
            >
              Innovation in Media
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/service3"
              style={{ color: "black", paddingLeft: "5px" }}
            >
              Measurable Impact
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/service4"
              style={{ color: "black", paddingLeft: "5px" }}
            >
              Proven Track Record
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/service5"
              style={{ color: "black", paddingLeft: "5px" }}
            >
              Strategic Storytelling
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/service6"
              style={{ color: "black", paddingLeft: "5px" }}
            >
              Synergy
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/work">
            <h5>Our Work</h5>
          </Nav.Link>
          <Nav.Link href="/contact">
            <h5>Contact</h5>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
