import React from "react";
import banner1 from "../../assets/banner1.png";
const Synergy = () => {
  return (
    <div className="main">
      <div className="custom-image-container">
        <img src={banner1} alt="Banner" className="custom-image" />
        <div className="image-overlay">
          <h1>Synergy</h1>
          <p>
            Experience a collaborative journey, where your goals, values, and
            vision are at the core of our dedicated partnership.
          </p>
        </div>
      </div>
      <div className="responsive-paragraph px-5 py-5">
        <p>
          At Sambriddhi Media Advertising Agency, our commitment to Synergy goes
          beyond the conventional norms of client-agency relationships. Choosing
          Sambriddhi is more than a transaction; it's an invitation to embark on
          a collaborative partnership that thrives on shared success and mutual
          growth. We believe that your success is not just a milestone; it's a
          journey we traverse together.
        </p>
        <h3>A Deeper Dive into Synergy</h3>
        <p>
          Our approach to Synergy is rooted in the belief that collaboration is
          the cornerstone of sustained success. It's not merely about providing
          a service; it's about immersing ourselves in your world—understanding
          your goals, embracing your values, and aligning with your vision. We
          don't just work for you; we work with you, side by side, ensuring that
          our efforts are seamlessly intertwined with your aspirations.
        </p>
        <h3>An Extension of Your Team</h3>
        <p>
          Choosing Sambriddhi means gaining more than an external service
          provider; it means adding a dedicated extension to your team. We
          integrate ourselves into your organizational fabric, aligning our
          strategies with your objectives. As collaborators, we share the
          responsibility, the challenges, and the victories. Your goals become
          our goals, and your vision becomes our shared horizon.
        </p>
        <h3>Dedicated to Your Success</h3>
        <p>
          This collaborative journey is not confined to the parameters of
          conventional partnerships. We consider ourselves not just service
          providers but vested stakeholders in your success story. Our
          dedication goes beyond delivering campaigns; it extends to working as
          an integral part of your team, committed to achieving success
          together.
        </p>
        <h3>Experience the True Essence of Partnership</h3>
        <p>
          Immerse yourself in the true essence of partnership with Sambriddhi
          Media Advertising Agency. The synergy we offer is more than a tagline;
          it's a commitment to collaborative excellence. From strategy sessions
          to campaign executions, we navigate this journey as partners dedicated
          to realizing your vision. Your success is not just a goal; it's a
          shared triumph, and every milestone we reach is a testament to the
          power of true collaborative synergy.
        </p>
        <p>
          Join us in redefining what partnership means in the realm of
          advertising. Experience a level of commitment and collaboration that
          transcends traditional norms, and let Sambriddhi be the catalyst for
          sustained success in your brand's journey. Together, let's create a
          legacy of achievements and milestones that stand as a testament to the
          power of collaborative synergy.
        </p>
      </div>
    </div>
  );
};

export default Synergy;
