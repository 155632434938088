import React from "react";
import banner1 from "../../assets/banner1.png";
const Proven = () => {
  return (
    <div className="main">
      <div className="custom-image-container">
        <img src={banner1} alt="Banner" className="custom-image" />
        <div className="image-overlay">
          <h1>Proven Track Record</h1>
          <p>
            Elevate your brand with our successful track record, catering to
            businesses of all sizes with impactful campaigns.
          </p>
        </div>
      </div>
      <div className="responsive-paragraph px-5 py-5">
        <p>
          The Proven Track Record at Sambriddhi Media Advertising Agency is not
          merely a badge of honor; it's a living testament to our unwavering
          commitment to excellence. Navigating the diverse and ever-shifting
          landscapes of advertising, we've not just created campaigns but
          orchestrated impactful experiences for startups, mid-sized businesses,
          and established brands alike.
        </p>
        <p>
          Embark on a journey through our extensive portfolio, and you'll
          encounter success stories that transcend industries. These narratives
          are not just moments in time; they are enduring testaments to our
          ability to transform businesses. From the exhilarating launches of
          nascent brands to the nuanced artistry of comprehensive rebranding
          endeavors, we've consistently been the architects behind businesses
          elevating their presence and achieving multifaceted marketing goals.
        </p>
        <p>
          As you delve into our portfolio, you'll witness the seamless
          integration of creativity, strategy, and sheer dedication. We don't
          just deliver campaigns; we craft success narratives that resonate with
          authenticity. The brands we've partnered with aren't merely names on a
          list; they are chapters in our ongoing book of success narratives.
        </p>
        <p>
          The invitation stands—partner with us, and let your brand become the
          next chapter in our evolving story. Together, we'll embark on a
          journey of transformation, where your brand doesn't just participate
          but takes center stage in the grand narrative of success that we
          continue to craft at Sambriddhi Media Advertising Agency. Explore the
          possibilities, and let your brand's story be the next masterpiece in
          our collection of success tales.
        </p>
      </div>
    </div>
  );
};

export default Proven;
