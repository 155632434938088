import React from "react";
import banner1 from "../../assets/banner1.png";
const Measurable = () => {
  return (
    <div className="main">
      <div className="custom-image-container">
        <img src={banner1} alt="Banner" className="custom-image" />
        <div className="image-overlay">
          <h1>Measurable Impact</h1>
          <p>
            Achieve tangible results as we focus on measurable impacts, ensuring
            every campaign contributes to your success.
          </p>
        </div>
      </div>
      <div className="responsive-paragraph px-5 py-5">
        <p>
          At Sambriddhi, we understand that while creativity serves as our
          language, it is the impact that becomes the true measure of our
          success. We go beyond the surface, transcending aesthetics to unlock a
          realm of Measurable Impact that becomes the heartbeat of your brand's
          journey.
        </p>
        <p>
          Our campaigns aren't randomly pieced together; they are meticulously
          and strategically orchestrated to contribute tangibly to your success
          metrics. We delve into the intricacies of your brand, understanding
          its unique nuances and objectives. It's not just about creating
          visually appealing content; it's about crafting a narrative that
          resonates, engages, and leaves a lasting impression.
        </p>
        <p>
          Whether the goal is amplifying brand visibility, enhancing engagement
          metrics, or improving conversion rates, our pride rests in delivering
          results that are not just seen but felt. Grounded in a meticulous,
          data-driven approach, each campaign transforms into a strategic
          investment, becoming a catalyst for your brand's journey toward
          enduring success.
        </p>
        <p>
          Join us as we redefine the parameters of impact, pushing the
          boundaries of what's achievable. It's a collaborative journey where
          creativity seamlessly intertwines with measurable triumphs, creating a
          narrative that transcends expectations and propels your brand to new
          heights. Let Sambriddhi be the architect of your brand's success
          story, where every campaign is not just an artistic expression but a
          tangible step toward triumph.
        </p>
      </div>
    </div>
  );
};

export default Measurable;
