import "./App.css";
import CustomNavbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Work from "./components/Work/Work";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Audience from "./components/Pages/Audience_Connection";
import Innovation from "./components/Pages/Innovation_in_Media";
import Measurable from "./components/Pages/Measurable_Impact";
import Proven from "./components/Pages/Proven_Track_Record";
import Strategic from "./components/Pages/Strategic_Storytelling";
import Synergy from "./components/Pages/Synergy";
function App() {
  return (
    <Router>
      <CustomNavbar />

      <Routes>
        <Route path="/" element={<Home />} index />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service1" element={<Audience />} />
        <Route path="/service2" element={<Innovation />} />
        <Route path="/service3" element={<Measurable />} />
        <Route path="/service4" element={<Proven />} />
        <Route path="/service5" element={<Strategic />} />
        <Route path="/service6" element={<Synergy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
