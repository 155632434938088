import React from "react";
import banner1 from "../../assets/banner1.png";
import { Container, Row, Col } from "react-bootstrap";
import pic3 from "../../assets/pic3.png";
import pic1 from "../../assets/pic1.png";
import pic5 from "../../assets/pic5.png";
import pic6 from "../../assets/pic6.png";
import pic7 from "../../assets/pic7.png";
import "./Work.css";
const Contact = () => {
  return (
    <div className="main">
      <div className="custom-image-container">
        <img src={banner1} alt="Banner" className="custom-image" />
        <div className="image-overlay">
          <h1>Our Work</h1>
          <p>
            Crafting Stories, Fostering Connections, and Elevating Brands to New
            Heights.
          </p>
        </div>
      </div>
      <Container className="mt-5">
        <Row>
          <Col>
            <p>
              At SAMRIDDHI MEDIA ADVERTISING AGENCY (SMAA), our suite of
              services is a testament to our commitment to creativity, purpose,
              and positive change. We go beyond conventional advertising,
              offering a diverse range of services that encompass cultural
              promotion, documentary creation, educational initiatives, and
              sustainable development. Explore the avenues where our expertise
              shines:
            </p>
          </Col>
        </Row>
        {/* Service 1: Cultural Promotion*/}
        <Row className="mt-4">
          <Col>
            <h3>1. Cultural Promotion:</h3>
            <h6>Celebrating Heritage, Unveiling Traditions</h6>
            <img
              src={pic3}
              alt="Cultural Promotion"
              className="img-fluid mb-5 mt-2"
              height="30%"
              width="30%"
            />
            <p>
              Immerse your audience in the rich cultural heritage of Nepal and
              beyond. Our cultural promotion services bring traditions to life,
              fostering a deeper understanding of diverse communities. From
              vibrant festivals to historical narratives, we illuminate the
              tapestry of cultures through engaging content creation.
            </p>
          </Col>
        </Row>
        {/* Service 2: Documentary Films and Videos */}
        <Row className="mt-4">
          <Col>
            <h3>2. Documentary Films and Videos:</h3>
            <h6>Transcending Boundaries through Cinematic Excellence</h6>
            <img
              src={pic1}
              alt="Documentary Films and Videos"
              className="img-fluid mb-5 mt-2"
              height="30%"
              width="30%"
            />
            <p>
              Our passion lies in storytelling that goes beyond borders.
              Discover the power of compelling documentaries and videos that
              capture the essence of cultural values, Buddhism, and global
              perspectives. Whether a short film or a feature-length
              documentary, we bring stories to life with cinematic brilliance.
            </p>
          </Col>
        </Row>
        {/* Service 3: Sustainable Development Initiatives */}
        <Row className="mt-4">
          <Col>
            <h3>3. Sustainable Development Initiatives:</h3>
            <h6>Making a Positive Impact on Communities</h6>
            <img
              src={pic5}
              alt="Sustainable Development Initiatives"
              className="img-fluid mb-5 mt-2"
              height="30%"
              width="30%"
            />
            <p>
              Join us in contributing to the United Nations' Sustainable
              Development Goals (SDG) 2030. Our sustainable development
              initiatives range from poverty alleviation programs to regional
              development projects. Partner with SMAA to integrate
              sustainability into every facet of your initiatives.
            </p>
          </Col>
        </Row>
        {/* Service 4: Training Programs and Events */}
        <Row className="mt-4">
          <Col>
            <h3>4. Training Programs and Events:</h3>
            <h6>Knowledge as a Catalyst for Change</h6>
            <img
              src={pic6}
              alt="Sustainable Development Initiatives"
              className="img-fluid mb-5 mt-2"
              height="30%"
              width="30%"
            />
            <p>
              Knowledge is a powerful catalyst for positive change. Engage with
              us in training programs and events that transcend traditional
              boundaries. Dive into contemporary issues, particularly in
              agricultural development. Our events serve as platforms for
              meaningful dialogue and knowledge exchange.
            </p>
          </Col>
        </Row>
        {/* Service 5: Education Proposals and Awareness Programs */}
        <Row className="mt-4">
          <Col>
            <h3>5. Education Proposals and Awareness Programs:</h3>
            <h6>Inspiring Change through Education</h6>
            <img
              src={pic7}
              alt="Education Proposals and Awareness Programs"
              className="img-fluid mb-5 mt-2"
              height="30%"
              width="30%"
            />
            <p>
              Education is the key to transformation. SMAA crafts content for
              education proposals and awareness programs that go beyond
              entertainment. From highlighting social issues to promoting
              cultural understanding, our services create a lasting impact on
              society.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
