import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import "./Testimonials.css";

const testimonialData = [
  {
    name: "Rekha Thapa",
    occupation: "Entrepreneur in Kathmandu",
    description:
      "Sambriddhi has added a unique flavor to our marketing strategies. Their storytelling techniques resonate well with our culturally diverse audience, making them a valuable asset for any Nepali business.",
  },
  {
    name: "Rajesh Shrestha",
    occupation: "Cultural Event Organizer in Pokhara",
    description:
      "Working with Sambriddhi feels like a collaboration rooted in our traditions. Their documentaries beautifully capture the essence of our cultural events, creating a bridge between our heritage and the global audience.",
  },
  {
    name: "Sunita Lama",
    occupation: "Non-Profit Advocate in Lalitpur",
    description:
      "Sambriddhi's commitment to sustainability aligns perfectly with our values. They have been instrumental in conveying our message to a wider audience, contributing significantly to our cause.",
  },
  {
    name: "Anil Bhandari",
    occupation: "Small Business Owner in Bhaktapur",
    description:
      "Choosing Sambriddhi was a game-changer for my business. Their tailored content and understanding of our local market dynamics have truly set them apart in the advertising landscape.",
  },
  {
    name: "Deepa Gurung",
    occupation: "Marketing Professional in Biratnagar",
    description:
      "Sambriddhi’s collaborative approach makes them stand out. They don’t just work for you; they work with you, making your brand's success their own.",
  },
  {
    name: "Arjun Thakuri",
    occupation: "Tourism Enthusiast in Dharan",
    description:
      "As someone passionate about tourism, I appreciate Sambriddhi's efforts in promoting Nepal's beauty. Their videos go beyond the conventional, showcasing our country's treasures to the world.",
  },
];

const Testimonials = () => {
  return (
    <Container fluid className="text-center my-5 px-5">
      <Row className="justify-content-center">
        {testimonialData.map((testimonial, index) => (
          <Col key={index} xs={12} md={6} lg={4} className="mb-4">
            <Card className="testimonial-card">
              <div className="user-icon">
                <FaUser />
              </div>
              <Card.Body>
                <Card.Title>{testimonial.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {testimonial.occupation}
                </Card.Subtitle>
                <Card.Text>{testimonial.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Testimonials;
