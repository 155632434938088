import React from "react";
import banner1 from "../../assets/banner1.png";
const Innovation = () => {
  return (
    <div className="main">
      <div className="custom-image-container">
        <img src={banner1} alt="Banner" className="custom-image" />
        <div className="image-overlay">
          <h1>Innovation in Media</h1>
          <p>
            Stay ahead in the dynamic media landscape with our innovative
            approach across digital trends.
          </p>
        </div>
      </div>
      <div className="responsive-paragraph px-5 py-5">
        <p>
          In the dynamic and fast-paced realm of media, innovation stands as the
          guiding force propelling Sambriddhi Media Advertising Agency forward.
          Dedicating ourselves to the pursuit of excellence, we constantly push
          boundaries, embrace emerging technologies, and steadfastly remain at
          the forefront of ever-evolving industry trends.
        </p>
        <p>
          Our commitment to Innovation in Media is not just a statement—it's a
          principle embedded in every facet of our work. It resonates in the
          diverse tapestry of our portfolio, where the seamless integration of
          digital excellence and traditional artistry creates a unique mosaic of
          possibilities.
        </p>
        <p>
          From crafting immersive digital experiences that transport audiences
          to new realms of engagement to leveraging time-tested traditional
          channels that evoke a sense of nostalgia, we traverse the full
          spectrum of possibilities. At Sambriddhi, we recognize that each brand
          is as distinct as a fingerprint, and our innovative strategies are
          meticulously tailored to ensure that your message not only reaches but
          resonates profoundly with your target audience.
        </p>
        <p>
          Join us on a journey that transcends the ordinary as we explore the
          limitless horizons of creative expression. Whether it's pioneering the
          latest digital trends or revitalizing traditional media channels, we
          invite you to be a part of an exploration where innovation knows no
          bounds. Let Sambriddhi be your compass in navigating the vast horizons
          of creativity, where every campaign is an opportunity to redefine
          what's possible in the ever-evolving landscape of media.
        </p>
      </div>
    </div>
  );
};

export default Innovation;
