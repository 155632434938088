import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer
      expand="lg"
      bg="light"
      variant="light"
      style={{ height: "120px", backgroundColor: "aliceblue" }}
    >
      <Container>
        <Row>
          <Col className="footer-copyright">
            <br />
            <p className="text-center">
              Copyright © 2024 Sambriddhi Media Advertising Agency | Powered by
              Webpal
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
