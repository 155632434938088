import React from "react";
import banner1 from "../../assets/banner1.png";
import "./About.css";
import { Container, Row, Col } from "react-bootstrap";
import pic2 from "../../assets/pic2.png";
import pic4 from "../../assets/pic4.png";
const About = () => {
  return (
    <div className="main">
      <div className="custom-image-container">
        <img src={banner1} alt="Banner" className="custom-image" />
        <div className="image-overlay">
          <h1>About Us</h1>
          <p>
            Crafting Stories, Fostering Connections, and Elevating Brands to New
            Heights.
          </p>
        </div>
      </div>
      <Container className="mt-5">
        <Row>
          <Col>
            <p className="mb-5 ">
              We are introducing you to the SAMRIDDHI MEDIA ADVERTISING AGENCY
              (SMAA) established in 2017 (2074 BS) under the small industry
              office of the government of Nepal at tripureshwor, Kathmandu which
              has been promoting cultural values and Buddhism for peace. This
              agency makes documentaries and promotes tourism for Buddhist
              philosophy in the world. Nepal is a developing country with a
              diverse population of multicultural and multi-language people
              living together.
            </p>

            <Row className="flex-md-row-reverse">
              <Col md={6}>
                <div className="parts1">
                  <h4 className="mb-4 ">Our Identity</h4>
                  <p className="mb-4">
                    SMAA is not just an agency; it is a manifestation of a
                    vision to make a positive impact on society. Rooted in the
                    rich cultural tapestry of Nepal, we are committed to
                    promoting cultural values, Buddhism, and contributing to
                    global peace through our diverse range of services. We are
                    focusing on the Sustainable development goal of SDG UN, 2030
                    to support the poverty alleviation program in the country.
                  </p>
                  <h4 className="mb-4">Our Core Essence</h4>
                  <p className="mb-4">
                    SMAA isn't just an agency; it's a commitment to weaving
                    cultural values and Buddhist philosophy into the fabric of
                    our work. Our identity is rooted in the diverse tapestry of
                    Nepal, where each project becomes a canvas for storytelling
                    that transcends borders.
                  </p>
                  <h4 className="mb-4">Sustainability at Our Core</h4>
                  <p className="mb-4">
                    SMAA isn't just about creating content; it's about creating
                    impact. Aligned with the United Nations' Sustainable
                    Development Goals (SDG) 2030, we actively contribute to
                    Nepal's poverty alleviation program and spearhead
                    initiatives for sustainable development in the Karnali
                    region. Sustainability isn't a checkbox for us; it's
                    embedded in our very identity.
                  </p>
                  <h4 className="mb-4">
                    Beyond Advertising: A Catalyst for Change
                  </h4>
                  <p className="mb-4">
                    More than an advertising agency, SMAA is a catalyst for
                    positive change. Our training programs and events transcend
                    traditional boundaries, offering platforms for knowledge
                    exchange on contemporary issues, particularly in
                    agricultural development. We believe in empowering
                    communities and fostering growth beyond the confines of
                    conventional advertising.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="parts2">
                  <img
                    src={pic2}
                    width="90%"
                    height="auto"
                    alt="pic2"
                    className="image"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="text-center">
            <h3 className="mb-4 mt-5">Purna Prasad Acharya</h3>
            <h4>Founder</h4>
            <img
              src={pic4}
              width="30%"
              height="auto"
              alt="pic3"
              className="image mx-auto d-block"
            />
            <h5 className="text-center m-5">
              "As the founder of SMAA, my vision was to create a space where
              creativity meets purpose. Our journey is an ode to cultural
              celebration, education, and sustainable development. Join us in
              our mission to craft meaningful content that transcends borders
              and leaves a lasting impact globally."
            </h5>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
